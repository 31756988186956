import store from "../store";

import { French } from "flatpickr/dist/l10n/fr.js";
import { English } from "flatpickr/dist/l10n/default";

const flatPickrLocale = {
  install: (Vue) => {
    Vue.prototype.$flatPickrLocale = () => {
      return French;
    };
  },
};

export default flatPickrLocale;
