import permissions from "../constants/permissions";
import CrmModuleLayout from "@/views/Pages/CrmModule/CrmModuleLayout.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListCustomerPage = () => import("@/views/Pages/CrmModule/CustomerManagement/ListCustomerPage.vue");
const AddCustomerPage = () => import("@/views/Pages/CrmModule/CustomerManagement/AddCustomerPage.vue");
const EditCustomerPage = () => import("@/views/Pages/CrmModule/CustomerManagement/EditCustomerPage.vue");
const ViewCustomerPage = () => import("@/views/Pages/CrmModule/CustomerManagement/ViewCustomerPage.vue");

const ListContactPage = () => import("@/views/Pages/CrmModule/ContactManagement/ListContactPage.vue");
const AddContactPage = () => import("@/views/Pages/CrmModule/ContactManagement/AddContactPage.vue");
const EditContactPage = () => import("@/views/Pages/CrmModule/ContactManagement/EditContactPage.vue");
const ViewContactPage = () => import("@/views/Pages/CrmModule/ContactManagement/ViewContactPage.vue");

const ListSupplierPage = () => import("@/views/Pages/CrmModule/SupplierManagement/ListSupplierPage.vue");
const AddSupplierPage = () => import("@/views/Pages/CrmModule/SupplierManagement/AddSupplierPage.vue");
const EditSupplierPage = () => import("@/views/Pages/CrmModule/SupplierManagement/EditSupplierPage.vue");
const ViewSupplierPage = () => import("@/views/Pages/CrmModule/SupplierManagement/ViewSupplierPage.vue");

const CrmPage = () => import("@/views/Pages/CrmModule/CrmPage.vue");

let customerManagementPages = {
  path: "customers",
  component: CrmModuleLayout,
  children: [
    {
      path: "",
      name: "List Customers",
      components: { default: ListCustomerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CUSTOMERS,
      },
    },
    {
      path: "add",
      name: "Add Customer",
      components: { default: AddCustomerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CUSTOMERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Customer",
      components: { default: EditCustomerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CUSTOMERS,
      },
    },
    {
      path: "view/:id",
      name: "View Customer",
      components: { default: ViewCustomerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CUSTOMERS,
      },
    }
  ],
};

let contactManagementPages = {
  path: "contacts",
  component: CrmModuleLayout,
  children: [
    {
      path: "",
      name: "List Contacts",
      components: { default: ListContactPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CONTACTS,
      },
    },
    {
      path: "add",
      name: "Add Contact",
      components: { default: AddContactPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CONTACTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Contact",
      components: { default: EditContactPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CONTACTS,
      },
    },
    {
      path: "view/:id",
      name: "View Contact",
      components: { default: ViewContactPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CONTACTS,
      },
    },
  ],
};

let suppliersManagementPages = {
  path: "suppliers",
  component: CrmModuleLayout,
  children: [
    {
      path: "",
      name: "List Suppliers",
      components: { default: ListSupplierPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SUPPLIERS,
      },
    },
    {
      path: "add",
      name: "Add Supplier",
      components: { default: AddSupplierPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_SUPPLIERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Supplier",
      components: { default: EditSupplierPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_SUPPLIERS,
      },
    },
    {
      path: "view/:id",
      name: "View Supplier",
      components: { default: ViewSupplierPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_SUPPLIERS,
      },
    },
  ],
};

const crmModuleRoutes = [
  {
    path: "",
    component: CrmModuleLayout,
    children: [
      {
        path: "",
        name: "Crm Page",
        components: { default: CrmPage },
        meta: { middleware: authenticated, },
      },
    ]
  },
  customerManagementPages,
  contactManagementPages,
  suppliersManagementPages,
];

export default crmModuleRoutes;
