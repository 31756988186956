<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_LOCATIONS)"
          true
          :link="{
            name: $t('COMMON.LOCATIONS'),
            icon: 'ni ni-map-big text-primary',
            path: '/admin/locations',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_ORGANIZATIONS)"
          true
          :link="{
            name: $t('COMMON.ORGANIZATIONS'),
            icon: 'ni ni-building text-primary',
            path: '/admin/organizations',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_RESELLERS)"
          true
          :link="{
            name: $t('COMMON.RESELLERS'),
            icon: 'ni ni-shop text-primary',
            path: '/admin/resellers',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_USERS)"
          true
          :link="{
            name: $t('COMMON.USERS'),
            icon: 'ni ni-single-02 text-primary',
            path: '/admin/users',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_ROLES)"
          true
          :link="{
            name: $t('COMMON.ROLES'),
            icon: 'ni ni-hat-3 text-primary',
            path: '/admin/roles',
          }"
        >
        </sidebar-item>

        <hr
          class="my-3"
          style="
            border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
            border-style: solid none none;
            border-width: 1px 0px 0px;
            border-image: none 100% / 1 / 0 stretch;
            min-width: 80%;
            overflow: visible;
            box-sizing: content-box;
            height: 0px;
          "
        />

        <lang-switcher />
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar
        :type="$route.meta.navbarType"
        :appModuleIcon="'ni ni-2x ni-settings-gear-65'"
        :appModuleName="$t('APPS.ADMIN')"
        :appNavBarClass="'bg-gray navbar-dark'"
      ></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";
import { LangSwitcher } from "@/components";
import DashboardNavbar from "../../Layouts/Authenticated/DashboardNavbar.vue";
import ContentFooter from "../../Layouts/Authenticated/ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    LangSwitcher,
  },
  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>