import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const jsona = new Jsona();
const url = process.env.VUE_APP_API_BASE_URL;

function upload(image, ressource_name, ressouce_id, field) {
  const payload = new FormData();
  payload.append("attachment", image);

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/uploads/${ressource_name}/${ressouce_id}/${field}`,
      payload,
      options
    )
    .then((response) => {
      return response.data.url;
    });
}

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/files`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/files/${id}?include=organization,object,tags,allowedLocations,roles,users,folder`, options)
    .then((response) => {
      let customer = jsona.deserialize(response.data);
      delete customer.links;
      return customer;
    });
}

function add(file) {
  const payload = jsona.serialize({
    stuff: file,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/files`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(file) {
  const payload = jsona.serialize({
    stuff: file,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/files/${file.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/files/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  upload,
};
