import permissions from "../constants/permissions";
import DefaultModuleLayout from "@/views/Pages/DefaultModule/DefaultModuleLayout.vue";

// import middleware
import authorized from "@/middleware/authorized";
import authenticated from "@/middleware/authenticated";

// import pages
const ViewLogPage = () => import("@/views/Pages/DefaultModule/LogManagement/ViewLogPage.vue");
const ViewProfilePage = () => import("@/views/Pages/DefaultModule/UserProfile/ViewProfilePage.vue");
const EditProfilePage = () => import("@/views/Pages/DefaultModule/UserProfile/EditProfilePage.vue");
const ListNotificationPage = () => import("@/views/Pages/DefaultModule/NotificationManagement/ListNotificationPage.vue");
const ViewNotificationPage = () => import("@/views/Pages/DefaultModule/NotificationManagement/ViewNotificationPage.vue");

let logManagementPages = {
  path: "logs",
  component: DefaultModuleLayout,
  children: [
    {
      path: "view/:id",
      name: "View Log",
      components: { default: ViewLogPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_LOGS,
      },
    },
  ],
};

let notificationManagementPages = {
  path: "notifications",
  component: DefaultModuleLayout,
  children: [
    {
      path: "",
      name: "List Notifications",
      components: { default: ListNotificationPage },
      meta: { middleware: authenticated, },
    },
    {
      path: "view/:id",
      name: "View Notification",
      components: { default: ViewNotificationPage },
      meta: { middleware: authenticated, },
    },
  ],
};

let userProfilePages = {
  path: "me",
  component: DefaultModuleLayout,
  children: [
    {
      path: "",
      name: "View Profile",
      components: { default: ViewProfilePage },
      meta: { middleware: authenticated, },
    },
    {
      path: "change",
      name: "Edit Profile",
      components: { default: EditProfilePage },
      meta: { middleware: authenticated, },
    },
  ],
};

const defaultModuleRoutes = [
  userProfilePages,
  logManagementPages,
  notificationManagementPages,
];

export default defaultModuleRoutes;
