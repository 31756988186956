export default {
  DEFAULT_MODULE: "Application",
  CRM_MODULE: "Crm",
  SALES_MODULE: "Ventes",
  PURCHASES_MODULE: "Achats",
  INVENTORY_MODULE: "Inventaire",
  FILE_EXPLORER_MODULE: "Explorateur de fichiers",
  ADMIN_MODULE: "Administration",
  CAFETERIA_MODULE: "Cafeterias",
  DASHBOARD: "Tableau de bord",
  RESELLER_MODULE: "Revendeur",
};
