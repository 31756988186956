import permissions from "../constants/permissions";
import CafeteriaModuleLayout from "@/views/Pages/CafeteriaModule/CafeteriaModuleLayout.vue";

//import middleware
import authorized from "@/middleware/authorized";
import authenticated from "@/middleware/authenticated";

//import pages
const CafeteriaPage = () => import("@/views/Pages/CafeteriaModule/CafeteriaPage.vue");

const ListCafeteriaPage = () => import("@/views/Pages/CafeteriaModule/CafeteriaManagement/ListCafeteriaPage.vue");
const AddCafeteriaPage = () => import("@/views/Pages/CafeteriaModule/CafeteriaManagement/AddCafeteriaPage.vue");
const EditCafeteriaPage = () => import("@/views/Pages/CafeteriaModule/CafeteriaManagement/EditCafeteriaPage.vue");
const ViewCafeteriaPage = () => import("@/views/Pages/CafeteriaModule/CafeteriaManagement/ViewCafeteriaPage.vue");


const ListEstablishmentPage = () => import("@/views/Pages/CafeteriaModule/EstablishmentManagement/ListEstablishmentPage.vue");
const AddEstablishmentPage = () => import("@/views/Pages/CafeteriaModule/EstablishmentManagement/AddEstablishmentPage.vue");
const EditEstablishmentPage = () => import("@/views/Pages/CafeteriaModule/EstablishmentManagement/EditEstablishmentPage.vue");
const ViewEstablishmentPage = () => import("@/views/Pages/CafeteriaModule/EstablishmentManagement/ViewEstablishmentPage.vue");


const ListServiceCenterPage = () => import("@/views/Pages/CafeteriaModule/ServiceCenterManagement/ListServiceCenterPage.vue");
const AddServiceCenterPage = () => import("@/views/Pages/CafeteriaModule/ServiceCenterManagement/AddServiceCenterPage.vue");
const EditServiceCenterPage = () => import("@/views/Pages/CafeteriaModule/ServiceCenterManagement/EditServiceCenterPage.vue");
const ViewServiceCenterPage = () => import("@/views/Pages/CafeteriaModule/ServiceCenterManagement/ViewServiceCenterPage.vue");


let cafeteriasManagementPages = {
  path: "cafeterias",
  component: CafeteriaModuleLayout,
  children: [
    {
      path: "",
      name: "List Cafeterias",
      components: { default: ListCafeteriaPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CAFETERIAS,
      },
    },
    {
      path: "add",
      name: "Add Cafeteria",
      components: { default: AddCafeteriaPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CAFETERIAS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Cafeteria",
      components: { default: EditCafeteriaPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CAFETERIAS,
      },
    },
    {
      path: "view/:id",
      name: "View Cafeteria",
      components: { default: ViewCafeteriaPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CAFETERIAS,
      },
    },
  ],
};

let establishmentsManagementPages = {
  path: "establishments",
  component: CafeteriaModuleLayout,
  children: [
    {
      path: "",
      name: "List Establishments",
      components: { default: ListEstablishmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_ESTABLISHMENTS,
      },
    },
    {
      path: "add",
      name: "Add Establishment",
      components: { default: AddEstablishmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_ESTABLISHMENTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Establishment",
      components: { default: EditEstablishmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_ESTABLISHMENTS,
      },
    },
    {
      path: "view/:id",
      name: "View Establishment",
      components: { default: ViewEstablishmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ESTABLISHMENTS,
      },
    },
  ],
};

let serviceCentersManagementPages = {
  path: "service-centers",
  component: CafeteriaModuleLayout,
  children: [
    {
      path: "",
      name: "List ServiceCenters",
      components: { default: ListServiceCenterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SERVICECENTERS,
      },
    },
    {
      path: "add",
      name: "Add ServiceCenter",
      components: { default: AddServiceCenterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_SERVICECENTERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit ServiceCenter",
      components: { default: EditServiceCenterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_SERVICECENTERS,
      },
    },
    {
      path: "view/:id",
      name: "View ServiceCenter",
      components: { default: ViewServiceCenterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_SERVICECENTERS,
      },
    },
  ],
};


const cafeteriaModuleRoutes = [
  {
    path: "",
    component: CafeteriaModuleLayout,
    children: [
      {
        path: "",
        name: "Cafeteria Page",
        components: { default: CafeteriaPage },
        meta: { middleware: authenticated, },
      },
    ]
  },
  serviceCentersManagementPages,
  establishmentsManagementPages,
  cafeteriasManagementPages,
];

export default cafeteriaModuleRoutes;
