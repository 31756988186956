import store from "../store";

const currencySymbol = process.env.VUE_APP_DEFAULT_CURRENCY_SYMBOL;

const formatCurrency = {
  install: (Vue) => {
    Vue.prototype.$formatCurrency = (value) => {
      if (value === null) {
        return "";
      }
      return `${currencySymbol} ${value.toFixed(2)}`;
    };
  },
};

export default formatCurrency;
