export default {
  CONTACTS_LIST: "Liste des contacts",
  ADD_CONTACT: "Ajouter un contact",
  CONTACT_ADDED: "Contact ajouté",
  EDIT_CONTACT: "Modifier un contact",
  CONTACT_UPDATED: "Contact mis a jour",
  DELETE_THIS_CONTACT: "Supprimer ce contact ?",
  CONTACT_DELETED: "Contact supprimé",
  MISTER: "MR",
  MADAM: "MME",
  CONTACT_TYPE: "Type de contact",
};
