import service from "@/store/services/locations-service";

export const state = {
  list: {},
  location: {},
  meta: {}
};

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, location) => {
    state.location = location;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then(location => {
      commit("SET_RESOURCE", location);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then(location => {
      commit("SET_RESOURCE", location);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then(location => {
      commit("SET_RESOURCE", location);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  location: state => state.location,
  dropdown: state => {
    return state.list.map(location => ({
      id: location.id,
      name: location.name
    }));
  }
};

const locations = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default locations;
