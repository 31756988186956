import store from "../store";

const fillUserOrganizationData = {
  install: (Vue) => {
    Vue.prototype.$fillUserOrganizationData = (object) => {
      const me = store.getters["profile/me"];
      if (!!me.organization?.id) {
        object.organization.id = me.organization.id;
      }
      if (me.restrict_to_locations) {
        if (object.allowedLocations.length === 0) {
          object.allowedLocations = [
            ...me.allowedLocations.map((item) => ({
              type: "locations",
              id: item.id,
            })),
          ];
        }
      }
      return object;
    };
  },
};

export default fillUserOrganizationData;
