import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/purchases-orders`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/purchases-orders/${id}?include=organization,issuer,items,allowedLocations,purchasesInvoice`, options)
    .then((response) => {
      let order = jsona.deserialize(response.data);
      delete order.links;
      return order;
    });
}

function add(order) {
  const payload = jsona.serialize({
    stuff: order,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/purchases-orders`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(order) {
  const payload = jsona.serialize({
    stuff: order,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/purchases-orders/${order.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/purchases-orders/${id}`, options);
}

function validate(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/purchases-orders/${id}/validate?include=organization,issuer,items,allowedLocations,purchasesInvoice`,
      options
    )
    .then((response) => {
      let order = jsona.deserialize(response.data);
      delete order.links;
      return order;
    });
}


function cancel(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/purchases-orders/${id}/cancel?include=organization,issuer,items,allowedLocations,purchasesInvoice`,
      options
    )
    .then((response) => {
      let order = jsona.deserialize(response.data);
      delete order.links;
      return order;
    });
}

function generateInvoice(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/purchases-orders/${id}/generate-invoice`,
      options
    )
    .then((response) => {
      let order = jsona.deserialize(response.data);
      delete order.links;
      return order;
    });
}

function generateDelivery({ id, items }) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/purchases-orders/${id}/generate-delivery`,
      items,
      options
    )
    .then((response) => {
      let delivery = jsona.deserialize(response.data);
      delete delivery.links;
      return delivery;
    });
}


export default {
  list,
  get,
  add,
  update,
  destroy,
  validate,
  cancel,
  generateInvoice,
  generateDelivery,
};
