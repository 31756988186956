export default {
  ROLES_LIST: "Liste des roles",
  ADD_ROLE: "Ajouter un role",
  ROLE_ADDED: "Role ajouté",
  ROLES_UPDATED: "Role modifié",
  AUTHORIZATIONS: "Autorisations",
  EDIT_ROLE: "Modifier le role",
  DELETE_THIS_ROLE: "Supprimer ce role ?",
  ROLE_DELETED: "Role supprimé",
};
