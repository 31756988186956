import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/subscriptions`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/subscriptions/${id}?include=organization,package`, options)
    .then((response) => {
      let subscription = jsona.deserialize(response.data);
      delete subscription.links;
      return subscription;
    });
}

function add(subscription) {
  const payload = jsona.serialize({
    stuff: subscription,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/subscriptions?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(subscription) {
  const payload = jsona.serialize({
    stuff: subscription,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/subscriptions/${subscription.id}?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/subscriptions/${id}`, options);
}

function renew(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/subscriptions/${id}/renew?include=organization,package`,
      options
    )
    .then((response) => {
      let subscription = jsona.deserialize(response.data);
      delete subscription.links;
      return subscription;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  renew,
};
