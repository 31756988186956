<template>
  <div v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS) && reseller">
    <router-link :to="{ name: 'View Reseller', params: { id: reseller.id } }">
      {{ `${reseller.name}` }}
    </router-link>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "reseller",

  components: {},

  mixins: [formMixin],

  props: {
    reseller: {
      type: Object,
      default: () => ({}),
      description: "Reseller",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    reseller(reseller) {},
  },
};
</script>
