export default {
  APP_NAME: "Cafétéria manager",
  EMAIL: "Courriel",
  PASSWORD: "Mot de passe",
  SIGN_IN: "Connexion",
  FORGOT_PASSWORD: "Mot de passe oublié ?",
  PAGE_NOT_FOUND: "La page demandée n'a pas été touvée",
  RETURN_HOME: "Retour a l'accueil",
  WELCOME: "Bienvenue",
  MY_PROFILE: "Mon profil",
  LOGOUT: "Déconnexion",
  DASHBOARD: "Tableau de bord",
  USERS: "Utilisateurs",
  ROLES: "Roles",
  ORGANIZATIONS: "Organizations",
  ORGANIZATION: "Organization",
  PRODUCTS: "Produits",
  PRODUCT: "Produit",
  ALL: "Tout",
  NAME: "Nom",
  ADDRESS: "Adresse",
  PHONE: "Téléphone",
  CREATED_AT: "Crée le",
  SEARCH: "Recherche",
  PER_PAGE: "Par page",
  VIEW: "Voir",
  EDIT: "Modifier",
  DELETE: "Supprimer",
  DISPLAY_FROM_X_TO_X_OF_X_ENTRIES:
    "Affichage de {from} à {to} sur {of} entrées",
  X_LINES_SELECTED: "{count} lignes sélectionnées",
  RETURN_TO_LIST: "Retour à la liste",
  SELECT_IMAGE: "Choisir une image",
  CHANGE_IMAGE: "Changer l'image",
  REMOVE_IMAGE: "Supprimer l'image",
  ROLE: "Role",
  FIRSTNAME: "Prénom",
  LASTNAME: "Nom",
  CONFIRM_PASSWORD: "Confirmer le mot de passe",
  RESELLERS: "Revendeurs",
  RESELLER: "Revendeur",
  LOCALE: "Langue",
  LOCALE_FR: "Francais",
  LOCALE_EN: "Anglais",
  GLOBAL: "Global",
  NONE: "Aucun",
  UPDATED_AT: "Mis a jour le",
  PERMISSIONS: "Permissions",
  ALL_ORGANIZATIONS: "Toutes les organizations",
  ALL_RESELLERS: "Tous les revendeurs",
  ALL_ROLES: "Tous les roles",
  ALL_USERS: "Tous les utilisateurs",
  ALL_PRODUCTS: "Tous les produits",
  EXCERPT: "Description",
  OWNER: "Proprietaire",
  USER: "Utilisateur",
  YES: "Oui",
  NO: "Non",
  OK: "OK",
  PICTURE: "Photo",
  GALLERY: "Gallerie",
  TAXES: "Taxes",
  PRICE: "Prix",
  RATE: "Taux (%)",
  CODE: "Code",
  DEFAULT_TAXES: "Taxes par defaut",
  INVOICES: "Factures",
  INVOICE: "Facture",
  RECIPIENT: "Destinataire",
  COUNTRY: "Pays",
  COUNTRIES: "Pays",
  STATES: "Regions",
  STATE: "Region",
  CITY: "Ville",
  ZIPCODE: "Code postal",
  NOTE: "Note",
  STATUS: "Statut",
  ALL_STATUSES: "Tous les statut",
  AMOUNT: "Montant",
  PAIED: "Payé",
  TOTAL: "Total",
  TOTAL_PAIED: "Total payé",
  CREATED_BY: "Créé par",
  DETAILS: "Détails",
  PAYMENTS: "Paiements",
  UNIT_PRICE: "Prix unitaire",
  SUBTOTAL: "Sous total",
  QUANTITY: "Quantité",
  DISCOUNT: "Réduction",
  REMAINING_PAYMENT_AMOUNT: "Reste à payer",
  DISCOUNT_PERCENT: "Réduction (%)",
  CLOSE: "Fermer",
  CUSTOMER: "Client",
  CUSTOMERS: "Clients",
  LOGS: "Logs",
  CAUSER: "Auteur",
  DATE: "Date",
  GO_BACK: "Retour",
  PACKAGE: "Forfait",
  PACKAGES: "Forfaits",
  ALL_PACKAGES: "Tous les forfaits",
  SUBSCRIPTION: "Inscription",
  SUBSCRIPTIONS: "Inscriptions",
  TYPE: "Type",
  TAX_TYPE_PERCENTAGE: "Pourcentage",
  TAX_TYPE_AMOUNT: "Montant",
  PAYMENT: "Paiement",
  ALL_INVOICES: "Toutes les factures",
  DISCOUNTS: "Réductions",
  DISCARD_CHANGES_AND_LEAVE_PAGE: "Abandonner les modifications et quitter?",
  COMPANY_NAME: "Nom de l'entreprise",
  BILLING_ENTITY_TYPE_INDIVIDUAL: "Particulier",
  BILLING_ENTITY_TYPE_COMPANY: "Entreprise",
  CONTACTS: "Contacts",
  CONTACT: "Contact",
  SUPPLIER: "Fournisseurs",
  SCHOOL: "Ecole",
  TITLE: "Titre",
  TITLES: "Titres",
  ALL_TITLES: "Tous les titres",
  TITLE_MR: "Monsieur",
  TITLE_MDM: "Madame",
  TAG: "Etiquette",
  TAGS: "Etiquettes",
  ADD_TAG: "Ajouter une etiquette",
  BIRTHDAY: "Date d'anniverssaire",
  SUPPLIERS: "Fournisseurs",
  FISCAL_NUMBER: "Numéro Fiscale",
  UPDATE: "Modifier",
  UPDATED: "Modifié",
  COLOR: "Couleur",
  LINK_CONTACT: "Lier le contact",
  ADD_CONTACT: "Ajouter un contact",
  SOURCE: "Source",
  LOCATIONS: "Emplacements",
  LOCATION: "Emplacement",
  ALL_LOCATIONS: "Tous les emplacements",
  FILES: "Fichiers",
  FILE: "Fichier",
  ALL_FILES: "Tous les fichiers",
  FOLDERS: "Dossiers",
  FOLDER: "Dossier",
  ALL_FOLDERS: "Tous les dossiers",
  FILES_EXPLORER: "Explorateur",
  CAFETERIAS: "Cafétérias",
  CAFETERIA: "Cafétéria",
  CUSTOMER_NUMBER: "Numéro client",
  ESTABLISHMENTS: "Établissements",
  ESTABLISHMENT: "Établissement",
  SERVICECENTERS: "Centres de service",
  SERVICECENTER: "Centre de service",
  WAREHOUSE: "Entrepot",
  WAREHOUSES: "Entrepots",
  PURCHASES_ORDER: "Commande achat",
  PURCHASES_ORDERS: "Commandes achat",
  ISSUER: "Emetteur",
  PURCHASES_INVOICE: "Facture achat",
  PURCHASES_INVOICES: "Factures achat",
  PURCHASES_DELIVERY: "Livraison achat",
  PURCHASES_DELIVERIES: "Livraisons achat",
  PURCHASES_PAYMENT: "Paiement achat",
  PURCHASES_PAYMENTS: "Paiements achat",
  SALES_ORDER: "Commande vente",
  SALES_ORDERS: "Commandes vente",
  SALES_INVOICE: "Facture vente",
  SALES_INVOICES: "Factures vente",
  SALES_DELIVERY: "Livraison vente",
  SALES_DELIVERIES: "Livraisons vente",
  SALES_PAYMENT: "Paiement vente",
  SALES_PAYMENTS: "Paiements vente",
  BILLING_INFORMATIONS: "Informations de facturation",
  BILLING_ADDRESS: "Adresse de facturation",
  RESELLER_PRODUCTS: "Produits revendeur",
  RESELLER_SERVICES: "Services revendeur",
  RESELLER_INVOICES: "Factures revendeur",
  RESELLER_PAYMENTS: "Paiements revendeur",
  SERVICES: "Services",
  SERVICE: "Service",
  RESELLER_PRODUCT: "Produit revendeur",
  RESELLER_PRODUCTS: "Produits revendeur",
  RESELLER_SERVICE: "Service revendeur",
  RESELLER_SERVICES: "Services revendeur",
};
