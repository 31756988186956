import service from "@/store/services/payments-service";

export const state = {
  list: {},
  payment: {},
  meta: {},
};

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, payment) => {
    state.payment = payment;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((payment) => {
      commit("SET_RESOURCE", payment);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((payment) => {
      commit("SET_RESOURCE", payment);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((payment) => {
      commit("SET_RESOURCE", payment);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  payment: (state) => state.payment,
};

const payments = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default payments;
