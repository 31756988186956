import currentUserCan from "../helpers/currentUserCan";
import store from "../store";
import permissions from "../constants/permissions";

const auth = {
  install: (Vue) => {
    Vue.prototype.$currentUserCan = (permission) => {
      let me = store.getters["profile/me"];
      return currentUserCan(me, permission);
    };
    Vue.prototype.$currentUser = () => {
      return store.getters["profile/me"];
    };
    Vue.prototype.$permissions = permissions;
    Vue.prototype.$currentUserIsLevelOrganization = () => {
      let me = store.getters["profile/me"];
      return !!me.organization?.id;
    };
    Vue.prototype.$currentUserIsLevelReseller = () => {
      let me = store.getters["profile/me"];
      return !!me.reseller?.id && !me.organization?.id;
    };
    Vue.prototype.$currentUserIsLevelAdmin = () => {
      let me = store.getters["profile/me"];
      return me.is_staff && !me.organization?.id && !me.reseller?.id;
    };
    Vue.prototype.$currentUserOrganizationId = () => {
      let me = store.getters["profile/me"];
      return me.organization?.id;
    };
    Vue.prototype.$currentUserResellerId = () => {
      let me = store.getters["profile/me"];
      return me.reseller?.id;
    };
  },
};

export default auth;
