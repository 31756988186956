import service from "@/store/services/reseller-payments-service";

export const state = {
  list: {},
  resellerPayment: {},
  meta: {},
};

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, resellerPayment) => {
    state.resellerPayment = resellerPayment;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((resellerPayment) => {
      commit("SET_RESOURCE", resellerPayment);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((resellerPayment) => {
      commit("SET_RESOURCE", resellerPayment);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((resellerPayment) => {
      commit("SET_RESOURCE", resellerPayment);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  resellerPayment: (state) => state.resellerPayment,
};

const resellerPayments = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default resellerPayments;
