<template>
  <div>
    <span v-for="(location, idx) in locations" :key="location.id">
      <router-link :to="{ name: 'View Location', params: { id: location.id } }">
        {{ `${location.name}` }}
      </router-link>
      {{ locations.length - 1 > idx ? "," : " " }}<br />
    </span>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "locations",

  components: {},

  mixins: [formMixin],

  props: {
    locations: {
      type: Array,
      default: () => [],
      description: "Locations",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    locations(locations) {},
  },
};
</script>
