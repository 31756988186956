export default {
  SAVE: "Enregistrer",
  EVENT_CREATE: "Création",
  EVENT_UPDATE: "Modification",
  EVENT_DELETE: "Suppression",
  SUBSCRIBE_TO_NOTIFICATIONS: "Souscrire aux notifications",
  SUBSCRIPTIONS_SAVED: "Souscriptions mises a jour",
  YOU_HAVE_X_UNREAD_NOTIFICATIONS: "Vous avez <strong class=\"text-primary\">{count}</strong> notifications non lues.",
  VIEW_ALL: "Voir les notifications",
  X_AGO: "il y a {duration}",
  XCAUSER_HAS_XEVENT_XOBJECTNAME: "<strong>{causer}</strong> a {event} <strong>{objectName}</strong>",
  DESCRIPTION_EVENT_CREATED: "créé",
  DESCRIPTION_EVENT_UPDATED: "modifié",
  DESCRIPTION_EVENT_DELETED: "supprimé",
  CAUSER: "Source",
  OBJECT: "Objet",
  NOTIFICATIONS_LIST: "Liste des notifications",
  READ: "Lu",
  UNREAD: "Non lu",
  ALL: "Tout",
  MARK_AS_READ: "Marquer comme lu",
  MARK_AS_UNREAD: "Marquer comme non lu",
};
