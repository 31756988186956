var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_DEFAULT) ||
    _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_SALES) ||
    _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_PURCHASES) ||
    _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_INVENTORY) ||
    _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_CRM) ||
    _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_FILE_EXPLORER) ||
    _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_CAFETERIA) ||
    _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_RESELLER) ||
    _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_ADMIN)
  )?_c('base-dropdown',{staticClass:"nav-item",attrs:{"menu-classes":"dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right","tag":"li","title-tag":"a","title-classes":"nav-link","icon":"ni ni-app ni-2x"}},[[_c('div',{staticClass:"row shortcuts px-4"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_DEFAULT))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'Dashboard' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar rounded-circle bg-success"},[_c('i',{staticClass:"ni ni-collection"})]),_c('small',[_vm._v(_vm._s(_vm.$t("APPS.DASHBOARD")))])])]}}],null,false,3045430845)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_CAFETERIA))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'Cafeteria Page' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar rounded-circle bg-success"},[_c('i',{staticClass:"ni ni-shop"})]),_c('small',[_vm._v(_vm._s(_vm.$t("APPS.CAFETERIA_MODULE")))])])]}}],null,false,2628034468)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_CRM))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'Crm Page' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar rounded-circle bg-success"},[_c('i',{staticClass:"fa fa-users"})]),_c('small',[_vm._v(_vm._s(_vm.$t("APPS.CRM_MODULE")))])])]}}],null,false,549504660)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_SALES))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'Sales Page' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar rounded-circle bg-success"},[_c('i',{staticClass:"ni ni-cart"})]),_c('small',[_vm._v(_vm._s(_vm.$t("APPS.SALES_MODULE")))])])]}}],null,false,1244411206)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_PURCHASES))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'Purchases Page' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar rounded-circle bg-success"},[_c('i',{staticClass:"ni ni-basket"})]),_c('small',[_vm._v(_vm._s(_vm.$t("APPS.PURCHASES_MODULE")))])])]}}],null,false,483611320)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_INVENTORY))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'Inventory Page' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar rounded-circle bg-success"},[_c('i',{staticClass:"ni ni-app"})]),_c('small',[_vm._v(_vm._s(_vm.$t("APPS.INVENTORY_MODULE")))])])]}}],null,false,3701307937)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_FILE_EXPLORER))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'Files Explorer' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar rounded-circle bg-success"},[_c('i',{staticClass:"ni ni-folder-17"})]),_c('small',[_vm._v(_vm._s(_vm.$t("APPS.FILE_EXPLORER_MODULE")))])])]}}],null,false,599066661)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_RESELLER))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'Reseller Page' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar rounded-circle bg-success"},[_c('i',{staticClass:"ni ni-shop"})]),_c('small',[_vm._v(_vm._s(_vm.$t("APPS.RESELLER_MODULE")))])])]}}],null,false,3026061048)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_APP_ADMIN))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'Admin Page' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar rounded-circle bg-gray"},[_c('i',{staticClass:"ni ni-settings-gear-65"})]),_c('small',[_vm._v(_vm._s(_vm.$t("APPS.ADMIN_MODULE")))])])]}}],null,false,2110423900)}):_vm._e()],1)]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }