<template>
  <base-dropdown class="lang-switcher">
    <base-button
      slot="title-container"
      type="secondary"
      class="dropdown-toggle"
    >
      <img
        :src="`/img/locale_flags/${$i18n.locale}.png`"
        style="max-width: 32px"
      />
      {{ $i18n.locale }}
    </base-button>
    <li
      v-for="lang in languages.filter((item) => item !== $i18n.locale)"
      :key="lang"
    >
      <a class="dropdown-item" href="#" @click="switchLang(lang)">
        <img :src="`/img/locale_flags/${lang}.png`" style="max-width: 32px" />
        {{ lang }}
      </a>
    </li>
  </base-dropdown>
</template>

<script>
import { loadLanguageAsync } from "../plugins/i18n";

export default {
  name: "lang-switcher",

  data: () => ({
    languages: ["fr", "en"],
  }),

  methods: {
    switchLang(lang) {
      loadLanguageAsync(lang);
    },
  },
};
</script>
<style></style>
