const capitalizeFirstLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
}
const objectRoute = {
  install: (Vue) => {
    Vue.prototype.$objectViewRoute = (object) => {
      if (object) {
        if (object.type) {
          if (object.type === 'purchases-deliveries') {
            return {
              name: `View PurchasesDelivery`,
              params: { id: object.id }
            };
          }
          if (object.type === 'sales-deliveries') {
            return {
              name: `View SalesDelivery`,
              params: { id: object.id }
            };
          }
          if (object.type.split('-').length > 1) {
            const objectRouteName = object.type
              .split('-')
              .map(item => capitalizeFirstLetter(item))
              .join('')
              .slice(0, -1);
            return {
              name: `View ${objectRouteName}`,
              params: { id: object.id }
            };
          }
          return {
            name: `View ${capitalizeFirstLetter(object.type).slice(0, -1)}`,
            params: { id: object.id }
          };
        }
      }
      return '/';
    };
  },
};

export default objectRoute;
