import DashboardModuleLayout from "@/views/Pages/DashboardModule/DashboardModuleLayout.vue";

// import middleware
import authenticated from "@/middleware/authenticated";

const Dashboard = () => import("@/views/Pages/DashboardModule/Dashboard/Dashboard.vue");


let dashBoardsPages = {
  path: "",
  component: DashboardModuleLayout,
  children: [
    {
      path: "dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: { middleware: authenticated },
    },
  ],
};

const dashboardModuleRoutes = [
  dashBoardsPages,
];

export default dashboardModuleRoutes;
