var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLER_INVOICES)
        )?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.RESELLER_INVOICES'),
          icon: 'ni ni-single-copy-04 text-primary',
          path: '/reseller/reseller-invoices',
        }}}):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLER_PAYMENTS)
        )?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.RESELLER_PAYMENTS'),
          icon: 'ni ni-money-coins text-primary',
          path: '/reseller/reseller-payments',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_SUBSCRIPTIONS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.SUBSCRIPTIONS'),
          icon: 'fa fa-id-card text-primary',
          path: '/reseller/subscriptions',
        }}}):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLER_PRODUCTS)
        )?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.RESELLER_PRODUCTS'),
          icon: 'fa fa-cubes text-primary',
          path: '/reseller/reseller-products',
        }}}):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLER_SERVICES)
        )?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.RESELLER_SERVICES'),
          icon: 'fa fa-cube text-primary',
          path: '/reseller/reseller-services',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PACKAGES))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.PACKAGES'),
          icon: 'ni ni-box-2 text-primary',
          path: '/reseller/packages',
        }}}):_vm._e(),_c('hr',{staticClass:"my-3",staticStyle:{"border-color":"rgba(0, 0, 0, 0.1) currentcolor currentcolor","border-style":"solid none none","border-width":"1px 0px 0px","border-image":"none 100% / 1 / 0 stretch","min-width":"80%","overflow":"visible","box-sizing":"content-box","height":"0px"}}),_c('lang-switcher')],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType,"appModuleIcon":'ni ni-2x ni-settings-gear-65',"appModuleName":_vm.$t('APPS.RESELLER_MODULE')}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }