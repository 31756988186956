<template>
  <router-view></router-view>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME,
      meta: (function () {
        if (process.env.VUE_APP_IS_DEMO == 0) {
          return [
            { charset: "utf-8" },
            {
              "http-equiv": "X-UA-Compatible",
              content: "IE=edge,chrome=1",
            },
            {
              name: "viewport",
              content:
                "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
            },
          ];
        }
        return [
          { charset: "utf-8" },
          {
            "http-equiv": "X-UA-Compatible",
            content: "IE=edge,chrome=1",
          },
          {
            name: "viewport",
            content:
              "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
          },
          {
            name: "keywords",
            content: process.env.VUE_APP_NAME,
          },
          {
            name: "description",
            content: process.env.VUE_APP_NAME,
          },
        ];
      })(),

      link: [
        { rel: "icon", type: "image/png", href: "/favicon.png" },
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700",
        },
        {
          rel: "stylesheet",
          href: "https://use.fontawesome.com/releases/v5.6.3/css/all.css",
          integrity:
            "sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/",
          crossorigin: "anonymous",
        },
      ],

      script: (function () {
        if (process.env.VUE_APP_IS_DEMO == 1) {
          return [
            /**{
              hid: "gtm-script1",
              innerHTML: `
                !function(e,n,a,t,c,s,l,d,i){n.className+=" "+a,s.start=1*new Date,s.end=l=function(){n.className=n.className.replace(RegExp(" ?"+a),"")},(e[t]=e[t]||[]).hide=s,setTimeout(function(){l(),s.end=null},c),s.timeout=c}(window,document.documentElement,"async-hide","dataLayer",4e3,{"GTM-K9BGS8K":!0});
              `,
              type: "text/javascript",
              charset: "utf-8",
            },**/
          ];
        }
      })(),
    };
  },
};
</script>
