var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_CONTACTS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.CONTACTS'),
          icon: 'ni ni-circle-08 text-primary',
          path: '/crm/contacts',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_CUSTOMERS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.CUSTOMERS'),
          icon: 'ni ni-circle-08 text-primary',
          path: '/crm/customers',
        }}}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_SUPPLIERS))?_c('sidebar-item',{attrs:{"true":"","link":{
          name: _vm.$t('COMMON.SUPPLIERS'),
          icon: 'ni ni-circle-08 text-primary',
          path: '/crm/suppliers',
        }}}):_vm._e(),_c('hr',{staticClass:"my-3",staticStyle:{"border-color":"rgba(0, 0, 0, 0.1) currentcolor currentcolor","border-style":"solid none none","border-width":"1px 0px 0px","border-image":"none 100% / 1 / 0 stretch","min-width":"80%","overflow":"visible","box-sizing":"content-box","height":"0px"}}),_c('lang-switcher')],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType,"appModuleIcon":'fa fa-2x fa-users',"appModuleName":_vm.$t('APPS.CRM_MODULE')}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }