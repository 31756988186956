import permissions from "../constants/permissions";
import InventoryModuleLayout from "@/views/Pages/InventoryModule/InventoryModuleLayout.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListProductPage = () => import("@/views/Pages/InventoryModule/ProductManagement/ListProductPage.vue");
const AddProductPage = () => import("@/views/Pages/InventoryModule/ProductManagement/AddProductPage.vue");
const EditProductPage = () => import("@/views/Pages/InventoryModule/ProductManagement/EditProductPage.vue");
const ViewProductPage = () => import("@/views/Pages/InventoryModule/ProductManagement/ViewProductPage.vue");

const ListWarehousePage = () => import("@/views/Pages/InventoryModule/WarehouseManagement/ListWarehousePage.vue");
const AddWarehousePage = () => import("@/views/Pages/InventoryModule/WarehouseManagement/AddWarehousePage.vue");
const EditWarehousePage = () => import("@/views/Pages/InventoryModule/WarehouseManagement/EditWarehousePage.vue");
const ViewWarehousePage = () => import("@/views/Pages/InventoryModule/WarehouseManagement/ViewWarehousePage.vue");


const InventoryPage = () => import("@/views/Pages/InventoryModule/InventoryPage.vue");


let productManagementPages = {
  path: "products",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List Products",
      components: { default: ListProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PRODUCTS,
      },
    },
    {
      path: "add",
      name: "Add Product",
      components: { default: AddProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_PRODUCTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Product",
      components: { default: EditProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_PRODUCTS,
      },
    },
    {
      path: "view/:id",
      name: "View Product",
      components: { default: ViewProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_PRODUCTS,
      },
    },
  ],
};


let warehouseManagementPages = {
  path: "warehouses",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List Warehouses",
      components: { default: ListWarehousePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_WAREHOUSES,
      },
    },
    {
      path: "add",
      name: "Add Warehouse",
      components: { default: AddWarehousePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_WAREHOUSES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Warehouse",
      components: { default: EditWarehousePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_WAREHOUSES,
      },
    },
    {
      path: "view/:id",
      name: "View Warehouse",
      components: { default: ViewWarehousePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_WAREHOUSES,
      },
    },
  ],
};

const inventoryModuleRoutes = [
  {
    path: "",
    component: InventoryModuleLayout,
    children: [
      {
        path: "",
        name: "Inventory Page",
        components: { default: InventoryPage },
        meta: { middleware: authenticated, },
      },
    ]
  },
  productManagementPages,
  warehouseManagementPages,
];

export default inventoryModuleRoutes;
