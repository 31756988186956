const objectDenomination = {
  install: (Vue) => {
    Vue.prototype.$objectDenomination = (object) => {
      if (object.type === 'customers') {
        return `${object.customer_name}`;
      }
      if (object.type === 'suppliers') {
        return `${object.company_name}`;
      }
      if (object.type === 'users') {
        return `${object.firstname} ${object.lastname}`;
      }
      if (['organizations', 'resellers'].includes(object.type)) {
        return `${object.name}`;
      }
      if (['purchases-orders', 'purchases-invoices', 'sales-orders', 'sales-invoices'].includes(object.type)) {
        return `${object.code}`;
      }
      return 'NO DENOMINATION PLEASE UPDATE PLUGIN objectDenomination.js';
    };
  },
};

export default objectDenomination;
