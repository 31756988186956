import permissions from "../constants/permissions";
import FilesExplorerModuleLayout from "@/views/Pages/FilesExplorerModule/FilesExplorerModuleLayout.vue";

//import middleware
import authorized from "@/middleware/authorized";

// import pages
const ListFilePage = () => import("@/views/Pages/FilesExplorerModule/FileManagement/ListFilePage.vue");
const FilesExplorerPage = () => import("@/views/Pages/FilesExplorerModule/FilesExplorer/FilesExplorerPage.vue");

let filesExplorerPages = {
  path: "",
  component: FilesExplorerModuleLayout,
  children: [
    {
      path: "explorer",
      name: "Files Explorer",
      components: { default: FilesExplorerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_FILES,
      },
    },
    {
      path: "files",
      name: "List Files",
      components: { default: ListFilePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_FILES,
      },
    },
  ],
};

const filesExplorerModuleRoutes = [
  filesExplorerPages,
];

export default filesExplorerModuleRoutes;
