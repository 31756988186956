export default {
  PURCHASES_ORDERS_LIST: "Liste des commandes",
  ADD_PURCHASES_ORDER: "Ajouter une commande",
  ADD_PURCHASES_ORDER_ITEM: "Ajouter une ligne",
  PURCHASES_ORDER_ADDED: "Commande ajoutée",
  OWNER: "Propriétaire",
  EDIT_PURCHASES_ORDER: "Modifier une commande",
  PURCHASES_ORDER_UPDATED: "Commande mise a jour",
  BILLING_ADDRESS: "Adresse de facturation",
  EXPIRATION_TIME: "Date d'expiration",
  ITEM_TYPE: "Type",
  SELECT_PRODUCTS: "Choisir un produit",
  SELECT_SUBSCRIPTIONS: "Choisir une inscription",
  SELECT_PACKAGES: "Choisir un forfait",
  DELETE_PURCHASES_ORDER_ITEM: "Supprimer la ligne",
  UPDATE_PURCHASES_ORDER_ITEM: "Editer la ligne",
  PURCHASES_ORDER_ITEM_DELETED: "Ligne supprimée",
  PURCHASES_ORDER_ITEM_ADDED: "Ligne ajoutée",
  PURCHASES_ORDER_ITEM_EDITED: "Ligne modifiée",
  SEND: "Envoyer",
  CANCEL: "Annuler",
  REFUND: "Rembourser",
  ADD_PAYMENT: "Ajouter un paiement",
  PURCHASES_ORDER_SENT: "Commande envoyée",
  SELECT_USERS: "Choisir l'utilisateur",
  SELECT_CUSTOMERS: "Choisir le client",
  SELECT_ORGANIZATIONS: "Choisir l'organization",
  SELECT_SUPPLIERS: "Choisir le fournisseur",
  DELETE_THIS_PURCHASES_ORDER: "Supprimer cette commande ?",
  PURCHASES_ORDER_DELETED: "Commande supprimée",
  CANCEL_PURCHASES_ORDER_QUESTION: "Annuler la commande ?",
  PURCHASES_ORDER_CANCELED: "Commande annulée",
  RECIPIENT: "Destinataire",
  ISSUER: "Emetteur",
  VALIDATE: "Valider",
  PURCHASES_ORDER_VALIDATED: "Commande validée",
  GENERATE_INVOICE: "Créer la facture",
  INVOICE_GENERATED: "Facture crée",
  VIEW_INVOICE: "Voir la facture",
  ADD_DELIVERY: "Ajouter une livraison",
  DELIVERIES_STATE: "Etat de livraison",
  DELIVERIES_STATE_ITEM: "Produit",
  DELIVERIES_STATE_ORDERED_QUANTITY: "Quantite commandée",
  DELIVERIES_STATE_DELIVERED_QUANTITY: "Quantite Livrée",
  DELIVERIES_STATE_REMAINING_QUANTITY: "Quantite Restant",
  DELIVERIES_STATE_REMAINING_QUANTITY: "Quantite Restant",
  NOT_INVOICED: "A Facturer",
  NOT_DELIVERED: "A Livrer",
  DELIVERED: "Livré",
};
